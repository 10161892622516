.landing-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.landing-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(10, 25, 47, 0.95) 0%, rgba(17, 34, 64, 0.95) 100%);
  z-index: 1;
}

.chart-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.chart-background #tradingview-chart {
  width: 100%;
  height: 100%;
  opacity: 0.15;
  filter: blur(8px);
  transform: scale(1.1);
}

.tradingview-widget-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.tradingview-widget-container > div {
  background: rgba(10, 25, 47, 0.8) !important;
  backdrop-filter: blur(5px);
}

.content-wrapper {
  position: relative;
  z-index: 2;
  backdrop-filter: blur(10px);
  background: rgba(10, 25, 47, 0.3);
  padding: 3rem;
  border-radius: 20px;
  border: 1px solid rgba(100, 255, 218, 0.1);
  margin-top: 60px;
  max-width: 800px;
  width: 90%;
  box-shadow: 0 8px 32px 0 rgba(10, 25, 47, 0.37);
}

.logo {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  letter-spacing: 2px;
  color: #64ffda;
  text-shadow: 0 0 20px rgba(100, 255, 218, 0.3);
}

.tagline {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0.9;
  color: #8892b0;
}

.coming-soon {
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 300;
  background: linear-gradient(45deg, #64ffda, #63f 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(100, 255, 218, 0.5);
}

.email-form {
  max-width: 500px;
  width: 90%;
  margin: 2rem auto;
}

.email-input {
  width: 100%;
  padding: 15px;
  margin-bottom: 1rem;
  border: 1px solid rgba(100, 255, 218, 0.2);
  border-radius: 5px;
  font-size: 1rem;
  background: rgba(10, 25, 47, 0.5);
  color: white;
  backdrop-filter: blur(10px);
}

.email-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.submit-button {
  padding: 15px 40px;
  border: 1px solid #64ffda;
  border-radius: 5px;
  background: transparent;
  color: #64ffda;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: rgba(100, 255, 218, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(100, 255, 218, 0.2);
}

.social-links {
  margin-top: 2rem;
}

.social-links a {
  color: #8892b0;
  margin: 0 15px;
  font-size: 1.5rem;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: #64ffda;
  opacity: 1;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .logo {
    font-size: 2rem;
  }
  
  .tagline {
    font-size: 1.2rem;
  }
  
  .coming-soon {
    font-size: 2.5rem;
  }

  .content-wrapper {
    padding: 2rem;
    margin-top: 80px;
  }
}
